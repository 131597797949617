@tailwind base;
@tailwind components;
@tailwind utilities;

.map-container {
    height: 500px;
}


.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    padding: 6px 12px;
    z-index: 1;
    margin: 12px;
}

.mini-sidebar {
    padding: 6px 12px;
    z-index: 1;
    margin: 12px;
}